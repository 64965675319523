import { useTranslation } from 'react-i18next';
import useLocalStorage from '../hooks/useLocalStorage';
import navigationMethods from '../utils/navigationUtils';
import { RELATIVE_PAGE_PATHS, SUBSCRIPTION_POPUP } from '../utils/constants';
import { useSelector } from 'react-redux';

const BuySubscriptionPopup = () => {
  const { t } = useTranslation();
  const [popupValue, setPopupValue] = useLocalStorage(SUBSCRIPTION_POPUP, 0);

  const isSubscribedToPaidStickers = useSelector(
    //@ts-ignore
    (state) => state.authentication.userData.isSubscribedToPaidStickers
  );

  const handleCancel = () => {
    setPopupValue(popupValue + 1);
  };

  const handleSubscribe = () => {
    setPopupValue(popupValue + 1);
    navigationMethods.navigate(RELATIVE_PAGE_PATHS.SUBSCRIPTION_PLANS);
  };

  if (isSubscribedToPaidStickers) return <></>;
  if (popupValue > 0 && !isSubscribedToPaidStickers) return <></>;
  return (
    <div className="flexContainerColumn centred popup-outer-container">
      <div
        id="pwa-container"
        className="flexContainerColumn flexContainerCentered pwa-inner-container"
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          className="card"
          style={{
            width: '-webkit-fill-available',
            padding: '15px 0 45px',
            textAlign: 'center',
            maxWidth: '677.750px',
          }}
        >
          <button
            className="close close-popup-button"
            onClick={() => handleCancel()}
          >
            <span aria-hidden="true">×</span>
          </button>

          <div className="card-body">
            <img
              src={navigationMethods.getSystemImageUrl(
                'popup-discount-icon.svg'
              )}
              alt="campaign-image"
              style={{
                width: '-webkit-fill-available',
                maxWidth: '300px',
                maxHeight: '300px',
              }}
            />

            <div
              className="pwa-header"
              style={{ width: '10px', margin: 'auto' }}
            >
              <p
                style={{
                  color: '#87ba3b',
                  fontWeight: 'bolder',
                  lineHeight: 1.5,
                }}
              >
                {t('payments:popup.buy').toUpperCase()}
              </p>
            </div>
            <p
              style={{
                color: 'black',
                width: '100px',
                fontWeight: 600,
                lineHeight: 1.2,
                margin: '0 auto 16px',
              }}
            >
              {t('payments:popup.description')}
            </p>
            <button
              className="greenBtn header next uppercase btn btn-secondary add-button"
              style={{
                marginBottom: '0',
                padding: '0 15px',
                width: 'fit-content',
              }}
              onClick={() => handleSubscribe()}
            >
              {t('payments:popup.button').toUpperCase()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySubscriptionPopup;
