import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { Col } from 'reactstrap';
import {
  RELATIVE_PAGE_PATHS,
  USER_DATA_IN_LOCAL_STORAGE,
} from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';
import NavigationComponent from './NavigationComponent';
import PaidFunctionalityScreen from './PaidFunctionalityScreen';
import TrackingScreen from './TrackingScreen';
import UsefulInfoScreen from './UsefulInfoScreen';
import FooterNavigationBar from './common/FooterNavigationBar';
import InternalServerErrorScreen from './errors/InternalServerErrorScreen';
import NotFoundErrorScreen from './errors/NotFoundErrorScreen';
import UnhandledErrorScreen from './errors/UnhandledErrorScreen';
import AddSticker from './journal/AddStickerScreen';
import ExpiredStickers from './journal/ExpiredStickersScreen';
import GoalsAndTags from './journal/GoalsAndTagsScreen';
import JournalReadOnlyScreen from './journal/JournalReadOnlyScreen';
import Journal from './journal/JournalScreen';
import PhotoPreviewScreen from './journal/PhotoPreviewScreen';
import RequestJournalDownloadScreen from './journal/RequestJournalDownloadScreen';
import SelectedTag from './journal/SelectedTagScreen';
import ChangePassword from './profile/ChangePasswordScreen';
import DeleteProfileScreen from './profile/DeleteProfileScreen';
import Profile from './profile/ProfileScreen';
import SpecialistDetailsScreen from './specialists/SpecialistDetailsScreen';
import SpecialistsScreen from './specialists/SpecialistsScreen';
import AddHomeScreen from './AddHomeScreen';
import HomeScreenComponent from './HomeScreenComponent';
import AdSlotSpecificPage from './common/AdSlotSpecificPage';
import ContactsScreen from './contacts/ContactsScreen';
import ContactDetailsScreen from './contacts/ContactDetailsScreen';
import StripeRouter from './stripe/StripeRouter';
import AdSlotSpecificPageFooter from './common/AdSlotSpecificPageFooter';
import AnalyticsPage from './analytics/AnalyticsPage';
import TermsAndConditions from './LegalPolicies/TermsAndConditions';
import Gdpr from './LegalPolicies/Gdpr';
import ContactPolicies from './LegalPolicies/ContactPolicies';
import BuySubscriptionPopup from './BuySubscriptionPopup';

class Main extends Component {
  render() {
    const isPhotoPreviewScreen = navigationMethods
      .getRoute()
      .startsWith(RELATIVE_PAGE_PATHS.PHOTO_PREVIEW);
    const allowLandscapeClass = isPhotoPreviewScreen ? 'allow-landscape' : '';

    const user = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
    let marginForBanner = '';
    if (user) {
      const userData = JSON.parse(user);
      marginForBanner = userData.isSubscribedToPaidStickers
        ? ''
        : 'margin-for-banner';
    }

    return (
      <Fragment>
        <div className="noPadding">
          <NavigationComponent />

          <Col
            xs={12}
            className="flexColumnCentered fix-padding-safari top-banner-container pr-0 pl-0"
          >
            <AdSlotSpecificPage />
          </Col>
          <div
            className={
              'flexContainerCentered centred noPadding ml-3 mr-3 main-section ' +
              allowLandscapeClass +
              marginForBanner
            }
          >
            <Switch>
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.HOME}
                component={GoalsAndTags}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.SELECTED_TAG + ':id'}
                component={SelectedTag}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.PROFILE}
                component={Profile}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.CHANGE_PASSWORD}
                component={ChangePassword}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.DELETE_PROFILE}
                component={DeleteProfileScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.JOURNAL}
                component={Journal}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.REQUEST_JOURNAL_DOWNLOAD}
                component={RequestJournalDownloadScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY}
                component={JournalReadOnlyScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.EXPIRED_STICKERS}
                component={ExpiredStickers}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ADD_STICKER + ':id'}
                component={AddSticker}
              />
              <Route
                path={RELATIVE_PAGE_PATHS.EDIT_STICKER + ':id'}
                exact
                component={AddSticker}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.SPECIALISTS}
                component={SpecialistsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.SPECIALISTS + ':id'}
                component={SpecialistDetailsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ADD_SPECIALISTS}
                component={SpecialistDetailsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.CONTACTS}
                component={ContactsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.CONTACTS + ':id'}
                component={ContactDetailsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ADD_CONTACT}
                component={ContactDetailsScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ADMIN_ACTIONS}
                component={PaidFunctionalityScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.PHOTO_PREVIEW + ':photo'}
                component={PhotoPreviewScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.USEFUL_INFO}
                component={UsefulInfoScreen}
              />
              <Route path="/breastfeeding">
                <Redirect to={RELATIVE_PAGE_PATHS.BREAST_FEEDING} />
              </Route>
              <Route
                path={RELATIVE_PAGE_PATHS.TRACKING}
                component={TrackingScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ANALYTICS}
                component={AnalyticsPage}
              />
              <Route
                path={RELATIVE_PAGE_PATHS.PAYMENTS}
                component={StripeRouter}
              />
              <Route
                path={RELATIVE_PAGE_PATHS.TERMS_AND_CONDITIONS}
                component={TermsAndConditions}
              />
              <Route path={RELATIVE_PAGE_PATHS.GDPR} component={Gdpr} />
              <Route
                path={RELATIVE_PAGE_PATHS.CONTACTS_POLICIES}
                component={ContactPolicies}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.INTERNAL_SERVER_ERROR}
                component={InternalServerErrorScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.INTERNAL_SERVER_ERROR}
                component={InternalServerErrorScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.NOT_FOUND}
                component={NotFoundErrorScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.UNHANDLED_ERROR}
                component={UnhandledErrorScreen}
              />
              <Route
                exact
                path={RELATIVE_PAGE_PATHS.ADDHOMESCREEN}
                component={AddHomeScreen}
              />
              <Route component={NotFoundErrorScreen} />
            </Switch>
          </div>
          <HomeScreenComponent />
          <AdSlotSpecificPageFooter />
          <BuySubscriptionPopup />
          <FooterNavigationBar className={allowLandscapeClass} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.userData,
  };
};

export default connect(mapStateToProps, {})(Main);
