import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  clearTrackingRecords,
  getTrackingRecordsByType,
  getAllTrackingRecords,
} from '../actions/trackingRecordsActions';
import {
  addTrackingRecord,
  updateTrackingRecord,
} from '../actions/trackingRecordsActions';
import {
  MULTI_VALUE_MEASUREMENT_SUBTYPES,
  RELATIVE_LINKS_PROD,
  RELATIVE_PAGE_PATHS,
} from '../../src/utils/constants';
import TrackerForm from './tracker/TrackerForm';
import TrackerRecordNote from './tracker/TrackerRecordNote';
import { selectTracker } from '../actions/trackerActions';
import { orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import TrackerHeader from './tracker/TrackerHeader';
import { MODAL_TYPE } from '../models/modalTypes';
import * as i18n from 'i18next';
import { showLockedTrackerModal } from '../actions';
import { getAxiosWithToken } from '../utils/webApi';
import { CLEAR_CHECKBOX } from '../actions/actionTypes';

const freeTrackers = [
  RELATIVE_LINKS_PROD.BREAST_FEEDING,
  RELATIVE_LINKS_PROD.SLEEP,
  RELATIVE_LINKS_PROD.CRY,
  RELATIVE_LINKS_PROD.FEEDING.toString(),
  RELATIVE_LINKS_PROD.WEIGHT_TRACKER.toString(),
  RELATIVE_LINKS_PROD.HEIGHT_TRACKER.toString(),
  RELATIVE_LINKS_PROD.HEAD_CIRCUMFERENCE_TRACKER.toString(),
  RELATIVE_LINKS_PROD.BATHING.toString(),
  RELATIVE_LINKS_PROD.MEDICINE.toString(),
  RELATIVE_LINKS_PROD.TEMPERATURE.toString(),
];

const TrackScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { typeId, recordId } = useParams();
  const [modal, setModal] = useState(MODAL_TYPE.DEFAULT);

  const childBirthDate = useSelector(
    (state) => state.authentication.userData.childDateOfBirth
  );

  const tracker = useSelector((state) => {
    return (
      state.trackers.length && state.trackers.find((x) => `${x.id}` === typeId)
    );
  });

  const id = useSelector((state) => state.authentication.userData.id);

  const records = useSelector((state) => state.trackingRecords);

  const filteredAndOrderedRecords = orderBy(
    records.filter((record) => record.subTypeId === tracker.id),
    (record) => new Date(record.date),
    ['desc']
  );

  const record = records.find((r) => `${r.id}` === recordId);

  useEffect(() => {
    checkUserIsSubscribedToPaidTrackers();
  }, []);

  useEffect(() => {
    // Get first batch of specific tracking records
    dispatch(getTrackingRecordsByType(typeId, 0));
  }, [typeId, dispatch]);

  useEffect(() => {
    dispatch(selectTracker(tracker));
    return () => dispatch(selectTracker(null));
  }, [tracker, dispatch]);

  const isMultiSelect = Object.values(
    MULTI_VALUE_MEASUREMENT_SUBTYPES
  ).includes(tracker.name);

  const checkUserIsSubscribedToPaidTrackers = async () => {
    try {
      const { data } = await getAxiosWithToken().get(`user/${id}`);

      if (
        !data.data.isSubscribedToPaidStickers &&
        !freeTrackers.includes(typeId)
      ) {
        const paidTrackerMsg = i18n.t(
          'trackers:trackingScreen:paid-trackers.not-paid-message'
        );
        dispatch(showLockedTrackerModal(paidTrackerMsg));
      }
    } catch (error) {
      console.error('Error fetching user data: ', error);
    }
  };

  const saveRecord = ({ id, subTypeId, quantity, date, additionalInfo }) => {
    if (id) {
      dispatch(
        updateTrackingRecord(
          id,
          date,
          quantity,
          isMultiSelect ? additionalInfo : record.additionalInfo,
          record.notes
        )
      );
      history.goBack();
    } else {
      dispatch(addTrackingRecord(subTypeId, date, quantity, additionalInfo));
    }
  };

  const cancelRecord = () => {
    history.goBack();
  };

  const saveNote = (note) => {
    if (note) {
      dispatch(
        updateTrackingRecord(
          record.id,
          record.date,
          record.quantity,
          record.additionalInfo,
          note
        )
      );
      history.goBack();
    }
  };

  const deleteNote = () => {
    dispatch(
      updateTrackingRecord(
        record.id,
        record.date,
        record.quantity,
        record.additionalInfo,
        ''
      )
    );
    history.replace(`${RELATIVE_PAGE_PATHS.BREAST_FEEDING}`);
  };

  const openNote = (id) => {
    setModal(MODAL_TYPE.NOTE);
    history.push(`${RELATIVE_PAGE_PATHS.TRACKING}/${tracker.id}/${id}`);
  };

  const cancelNote = () => {
    setModal(MODAL_TYPE.DEFAULT);
    history.replace(`${RELATIVE_PAGE_PATHS.TRACKING}/${tracker.id}`);
    dispatch({ type: CLEAR_CHECKBOX });
  };

  if (tracker)
    return (
      <>
        <TrackerHeader
          childBirthDate={childBirthDate}
          tracker={tracker}
          lastRecord={filteredAndOrderedRecords[0] || {}}
        />
        <TrackerForm
          tracker={tracker}
          records={filteredAndOrderedRecords}
          record={record}
          onSave={saveRecord}
          onCancel={cancelRecord}
          onOpenNote={openNote}
          modalType={modal}
        />
        {modal === MODAL_TYPE.NOTE && (
          <TrackerRecordNote
            currentNote={record?.notes || ''}
            onSave={saveNote}
            onCancel={cancelNote}
            onDelete={deleteNote}
          />
        )}
      </>
    );
  else return <div>Missing tracker</div>;
};

export default TrackScreen;
