import { useField } from 'react-final-form';
import {
  MULTI_VALUE_MEASUREMENT_SUBTYPES,
  MULTIVALUE_COLOR_TEMPLATES,
} from '../../../utils/constants';
import { string } from 'yargs';

export const medicineUnits = [
  'trackers:trackingScreen:units.empty',
  'trackers:trackingScreen:units.drops',
  'trackers:trackingScreen:units.spoons',
  'trackers:trackingScreen:units.g',
  'trackers:trackingScreen:units.ml',
  'trackers:trackingScreen:units.dissolved-capsules',
  'trackers:trackingScreen:units.granules',
  'trackers:trackingScreen:units.tablets-striped',
  'trackers:trackingScreen:units.smearing',
];

export const feedingUnits = [
  'trackers:trackingScreen:units.teaspoons',
  'trackers:trackingScreen:units.g',
  'trackers:trackingScreen:units.ml',
  'trackers:trackingScreen:units.tea-cup',
  'trackers:trackingScreen:units.pcs',
];

export const feedingFoodForm = [
  'trackers:trackingScreen:foodForm.puree',
  'trackers:trackingScreen:foodForm.porridge',
  'trackers:trackingScreen:foodForm.cerealMilkPorridge',
  'trackers:trackingScreen:foodForm.mashed',
  'trackers:trackingScreen:foodForm.grated',
  'trackers:trackingScreen:foodForm.sliced',
  'trackers:trackingScreen:foodForm.minced',
  'trackers:trackingScreen:foodForm.fingerFood',
  'trackers:trackingScreen:foodForm.broth',
  'trackers:trackingScreen:foodForm.soup',
  'trackers:trackingScreen:foodForm.meal',
  'trackers:trackingScreen:foodForm.salad',
  'trackers:trackingScreen:foodForm.bread',
  'trackers:trackingScreen:foodForm.sliceBread',
  'trackers:trackingScreen:foodForm.crouton',
  'trackers:trackingScreen:foodForm.biscuit',
  'trackers:trackingScreen:foodForm.pasta',
  'trackers:trackingScreen:foodForm.mush',
  'trackers:trackingScreen:foodForm.banitsa',
  'trackers:trackingScreen:foodForm.cake',
  'trackers:trackingScreen:foodForm.cream',
  'trackers:trackingScreen:foodForm.semolina',
  'trackers:trackingScreen:foodForm.smothie',
  'trackers:trackingScreen:foodForm.juice',
  'trackers:trackingScreen:foodForm.nutButter',
];

interface DropdownOption {
  path: string;
  hasImage: boolean;
  imgType: string;
}

interface Category {
  categoryName: string;
  categoryOptions: DropdownOption[];
}

interface ColorTemplate {
  background: string;
  color: string;
  justifyContent: string;
  opacity: number;
}

interface MultiValueDropdowns {
  name: string;
  categories: Category[];
  color: ColorTemplate;
}

export const medicineDropdown: MultiValueDropdowns[] = [
  {
    name: 'multi-select-0',
    categories: [
      { categoryName: 'category-name-0', categoryOptions: [] },
      { categoryName: 'category-name-1', categoryOptions: [] },
      { categoryName: 'category-name-2', categoryOptions: [] },
      { categoryName: 'category-name-3', categoryOptions: [] },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.GREEN,
  },
  {
    name: 'multi-select-1',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.jpeg' },
        ],
      },
      { categoryName: 'category-name-1', categoryOptions: [] },
      { categoryName: 'category-name-2', categoryOptions: [] },
      { categoryName: 'category-name-3', categoryOptions: [] },
      { categoryName: 'category-name-4', categoryOptions: [] },
      { categoryName: 'category-name-5', categoryOptions: [] },
      { categoryName: 'category-name-6', categoryOptions: [] },
      { categoryName: 'category-name-7', categoryOptions: [] },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.PINK,
  },
  {
    name: 'multi-select-2',
    categories: [{ categoryName: 'category-name-0', categoryOptions: [] }],
    color: MULTIVALUE_COLOR_TEMPLATES.PINK,
  },
];

export const feedingDropdown: MultiValueDropdowns[] = [
  {
    name: 'multi-select-0',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-19', hasImage: true, imgType: '.jpg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-20', hasImage: true, imgType: '.jpg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
          { path: 'option-22', hasImage: true, imgType: '.jpg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
          { path: 'option-21', hasImage: true, imgType: '.jpg' },
          { path: 'option-4', hasImage: true, imgType: '.svg' },
          { path: 'option-5', hasImage: true, imgType: '.svg' },
          { path: 'option-6', hasImage: true, imgType: '.svg' },
          { path: 'option-23', hasImage: true, imgType: '.jpg' },
          { path: 'option-7', hasImage: true, imgType: '.svg' },
          { path: 'option-8', hasImage: true, imgType: '.svg' },
          { path: 'option-9', hasImage: true, imgType: '.svg' },
          { path: 'option-10', hasImage: false, imgType: '.svg' },
          { path: 'option-11', hasImage: true, imgType: '.svg' },
          { path: 'option-12', hasImage: true, imgType: '.svg' },
          { path: 'option-13', hasImage: false, imgType: '.svg' },
          { path: 'option-14', hasImage: false, imgType: '.svg' },
          { path: 'option-15', hasImage: true, imgType: '.svg' },
          { path: 'option-16', hasImage: false, imgType: '.svg' },
          { path: 'option-17', hasImage: false, imgType: '.svg' },
          { path: 'option-18', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.GREEN,
  },
  {
    name: 'multi-select-5',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-11', hasImage: true, imgType: '.jpg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-12', hasImage: true, imgType: '.jpg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
          { path: 'option-13', hasImage: true, imgType: '.jpg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
          { path: 'option-4', hasImage: true, imgType: '.svg' },
          { path: 'option-14', hasImage: true, imgType: '.jpg' },
          { path: 'option-5', hasImage: true, imgType: '.svg' },
          { path: 'option-15', hasImage: true, imgType: '.jpg' },
          { path: 'option-6', hasImage: true, imgType: '.svg' },
          { path: 'option-7', hasImage: true, imgType: '.svg' },
          { path: 'option-8', hasImage: true, imgType: '.svg' },
          { path: 'option-9', hasImage: false, imgType: '.svg' },
          { path: 'option-10', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.PINK,
  },
  {
    name: 'multi-select-1',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
          { path: 'option-3', hasImage: false, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.ORANGE,
  },
  {
    name: 'multi-select-2',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
          { path: 'option-4', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.DARK_ORANGE,
  },
  {
    name: 'multi-select-7',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-2', hasImage: false, imgType: '.svg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
          { path: 'option-4', hasImage: false, imgType: '.svg' },
          { path: 'option-5', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.BROWN,
  },
  {
    name: 'multi-select-4',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-6', hasImage: true, imgType: '.jpg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-7', hasImage: true, imgType: '.jpg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
          { path: 'option-8', hasImage: true, imgType: '.jpg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
          { path: 'option-9', hasImage: true, imgType: '.jpg' },
          { path: 'option-4', hasImage: true, imgType: '.svg' },
          { path: 'option-10', hasImage: true, imgType: '.jpg' },
          { path: 'option-5', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.PURPLE,
  },
  {
    name: 'multi-select-6',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
          { path: 'option-4', hasImage: true, imgType: '.svg' },
          { path: 'option-5', hasImage: true, imgType: '.svg' },
          { path: 'option-6', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.MINT,
  },
  {
    name: 'multi-select-3',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.LIGHT_ORANGE,
  },

  {
    name: 'multi-select-9',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.DARK_GREEN,
  },
  {
    name: 'multi-select-10',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.GREEN,
  },
  {
    name: 'multi-select-12',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.GREEN,
  },
  {
    name: 'multi-select-8',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: true, imgType: '.svg' },
          { path: 'option-2', hasImage: true, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.LIGHT_BLUE,
  },
  {
    name: 'multi-select-11',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [
          { path: 'option-0', hasImage: true, imgType: '.svg' },
          { path: 'option-1', hasImage: false, imgType: '.svg' },
          { path: 'option-2', hasImage: false, imgType: '.svg' },
          { path: 'option-3', hasImage: true, imgType: '.svg' },
          { path: 'option-4', hasImage: true, imgType: '.svg' },
          { path: 'option-5', hasImage: false, imgType: '.svg' },
          { path: 'option-6', hasImage: false, imgType: '.svg' },
          { path: 'option-7', hasImage: false, imgType: '.svg' },
          { path: 'option-8', hasImage: false, imgType: '.svg' },
        ],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.PINK,
  },
  {
    name: 'multi-select-13',
    categories: [
      {
        categoryName: 'category-name-0',
        categoryOptions: [],
      },
    ],
    color: MULTIVALUE_COLOR_TEMPLATES.PINK,
  },
];

export const headerColorStyles = [
  {
    background: '#f1ffd5',
    color: '#87ba3b',
    justifyContent: 'center',
    opacity: 1,
  },
  {
    background: '#FFE1EB',
    color: '#CC8EAC',
    justifyContent: 'center',
    opacity: 1,
  },
];

export const MODAL_DIALOG_STATE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  CLOSED: 'CLOSED',
  DELETE: 'DELETE',
};

export const useFormField = (fieldName: string) => {
  const { input } = useField(fieldName);
  return { input };
};

export const isFeedingTracker = (subType: string) => {
  return subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING;
};

export const isMedicineTracker = (subType: string) => {
  return subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE;
};
