import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import navigationMethods from '../../../../utils/navigationUtils';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import {
  isImageBelowMaxSize,
  isSupportedImageFormat,
} from '../../../../utils/commonUtils';
import {
  LANGUAGE_CODE_KEY,
  MULTI_VALUE_MEASUREMENT_SUBTYPES,
  STICKER_FILE_SIZE_LIMIT_IN_MB,
} from '../../../../utils/constants';
import { useDispatch } from 'react-redux';
import { showErrorModal, showSuccessModal } from '../../../../actions';
import { getAxiosWithToken } from '../../../../utils/webApi';
import { MODAL_DIALOG_STATE } from '../utils';
import { IProductDialog } from '../IProductDialogInterface.interface';

const AddProductDialog: React.FC<IProductDialog> = ({
  setModalDialogState,
  selectedCategoryId,
  loadCategoryItems,
  subType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClose = () => {
    setImagePreviewUrl(null);
    setFile(null);
    setModalDialogState(MODAL_DIALOG_STATE.CLOSED);
  };

  const chooseFile = (event: React.MouseEvent) => {
    // event.preventDefault();
    if (inputRef.current && event.currentTarget.id != 'filechose_button') {
      inputRef.current.click();
    }
  };

  const uploadImgContent = imagePreviewUrl ? (
    <img
      style={{ height: 'inherit' }}
      alt="Preview"
      src={imagePreviewUrl}
      className="image-preview"
    />
  ) : (
    <img
      style={{ height: 50, width: 50 }}
      alt="camera-svg"
      src={navigationMethods.getSystemImageUrl('camera.svg')}
      className="image-preview"
    />
  );

  const onSubmit = async ({ product }: { product: string }, form: any) => {
    const formData = new FormData();

    const data = {
      productName: product,
      productCategoryItem: selectedCategoryId,
    };
    formData.append('json-data', JSON.stringify(data));
    if (file) {
      formData.append('photo-file', file);
    }
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    formData.append('languageCode', languageCode);
    const response = await getAxiosWithToken(true).post(
      'categoryitem/products',
      formData
    );

    const returnedData = response.data;
    handleClose();
    if (returnedData.success) {
      if (subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE) {
        dispatch(
          showSuccessModal(
            t('trackers:productDialog:successfulCategoryItemText'),
            false
          )
        );
      } else if (subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING) {
        dispatch(
          showSuccessModal(
            t('trackers:productDialog:successfulCategoryItemTextFeeding'),
            false
          )
        );
      }

      loadCategoryItems();
    } else if (returnedData.code === 406) {
      const { userFriendlyMessage } = returnedData;
      dispatch(showErrorModal(userFriendlyMessage));
    }
  };

  const onChangeFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const selectedFile = event.target.files?.[0]; // Use optional chaining

    if (selectedFile) {
      let reader = new FileReader();
      // Rest of your code
      if (selectedFile && selectedFile.size) {
        let maxSizeExceeded = isImageBelowMaxSize(selectedFile.size);

        if (!maxSizeExceeded) {
          const message = t('stickers:addStickerScreen:maxSizeExceeded', {
            mb: STICKER_FILE_SIZE_LIMIT_IN_MB,
          });
          handleClose();
          dispatch(showErrorModal(message));

          return;
        }
      }

      if (selectedFile && selectedFile.type) {
        let isImageFormatSupported = isSupportedImageFormat(selectedFile.type);

        if (!isImageFormatSupported) {
          const message = t(
            'stickers:addStickerScreen:imageFormatNotSupported'
          );
          handleClose();
          dispatch(showErrorModal(message));

          return;
        }
      }

      reader.onload = () => {
        //@ts-ignore
        setImagePreviewUrl(reader.result);
        setFile(selectedFile);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <Dialog maxWidth={'xs'} fullWidth={true} onClose={handleClose} open={true}>
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (!values.product) {
            errors.product = 'Required';
          }

          return errors;
        }}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            id="product-dialog-form"
          >
            <DialogContent style={{ padding: '20px 24px 0px 24px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '5px',
                }}
              >
                <div
                  className="itemUploadImg photo-container-holder"
                  onClick={chooseFile}
                  id="filechose_button"
                >
                  {uploadImgContent}
                  <input
                    ref={inputRef}
                    className={'uploader'}
                    type="file"
                    onChange={(e) => {
                      onChangeFileUpload(e);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  color: '#6c757d',
                  fontSize: 12,
                  textAlign: 'center',
                  marginBottom: '15px',
                }}
              >
                {t('trackers:productDialog:notNecessaryUpload')}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Field name="product">
                  {({ input }) => (
                    <TextField
                      label={t('trackers:productDialog:textfieldLabel')}
                      variant="outlined"
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <DialogActions
                style={{
                  padding: '15px 8px 15px 8px',
                  justifyContent: 'space-around',
                }}
              >
                <Button
                  variant="contained"
                  className="delete-button"
                  onClick={handleClose}
                >
                  {t('trackers:productDialog:cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="save-button"
                >
                  {t('trackers:productDialog:save')}
                </Button>
              </DialogActions>
            </DialogContent>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default AddProductDialog;
