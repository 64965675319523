import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { CANCELED, COMPLETE, OPEN, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import { showSubscribeModal, startLoader, stopLoader } from '../../actions';
import { STRIPE_PUBLIC_KEY } from '../../utils/constants';
import { getAxiosWithToken } from '../../utils/webApi';
import * as i18n from 'i18next';
import { Redirect, useHistory } from 'react-router';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ location }) => {
  const fetchClientSecret = useCallback(async () => {
    try {
      const response = await getAxiosWithToken().post('billing/create-checkout-session', {
        userId: location.state.userId,
        priceId: location.state.subscription.priceId,
        customerId: location.state.subscription.customer.id,
        isSubscriptionInRenewalPeriod: String(location.state.subscription.isSubscriptionInRenewalPeriod),
        isActivatingFreeTrial: location.state.isActivatingFreeTrial
      });

      const { data } = response;
      return data.clientSecret;

    } catch (error) {
      console.error(`Error while processing the payment... \n${error}`);
    }
  }, [location]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" style={{ paddingBottom: '80px', width: '-webkit-fill-available', marginLeft: '-0.6rem', marginRight: '-0.6rem' }}>

      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

const Return = () => {
  const userId = useSelector(state => state.authentication.userData.id);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoader());
    returnSessionStatusFromCheckout();
  }, []);

  const returnSessionStatusFromCheckout = async () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const sessionsStatusQueryString = `session_id=${urlParams.get('session_id')}`
      + `&price_id=${urlParams.get('price_id')}`
      + `&customer_id=${urlParams.get('customer_id')}`
      + `&renewal=${urlParams.get('renewal')}`
      + `&user_id=${userId}`
      + `&freeTrial=${urlParams.get('freeTrial')}`;

    const { data } = await getAxiosWithToken().get(`billing/session-status?${sessionsStatusQueryString}`);

    const status = data.status
    if (status) {
      switch (status) {
        case OPEN:
          dispatch(stopLoader());
          return <Redirect path='payments/checkout' />
        case COMPLETE:
          dispatch(stopLoader());
          dispatch(showSubscribeModal(i18n.t('payments:successfulSubscription')));
          history.replace(RELATIVE_PAGE_PATHS.SUBSCRIPTION_PLANS);
          break;
        case CANCELED:
          dispatch(stopLoader());
          dispatch(showSubscribeModal(i18n.t('payments:cancelledSubscription')));
          history.replace(RELATIVE_PAGE_PATHS.SUBSCRIPTION_PLANS);
          break;
        default:
          dispatch(stopLoader());
          console.error('Unhandled payment status: ' + status);
          return dispatch(showSubscribeModal(i18n.t('payments:defaultErrorMsg')));
      }
    }
  }
  return null;
}

export { CheckoutForm, Return };